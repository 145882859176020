import React from "react";
import { FaMusic, FaAnchor, FaMap } from "react-icons/fa";

const DATA = {
  legalEntity: {
    name: "Forró Sailing Week GbR",
    address: "Auguste-Viktoria-Str. 40A",
    zip: "14199",
    city: "Berlin",
    phone: "+49 15208619419",
    email: "forrosailingweek@gmail.com",
    site: "https://forrosailingweek.com/",
  },
  stakeholders: [
    {
      role: "Authorized representatives",
      name: "Bernardo Lichtenberg Low-Beer, Milena-Marie Zöller",
    },
    {
      role: "VAT Number (VIES Number or USt-IdNr.)",
      name: "DE362082450",
    },
    {
      role: "Tax Number (Steuernummer)",
      name: "24/419/701227",
    },
  ],
  disclaimer: [
    {
      title: "Accountability for content",
      text: `The contents of our pages have been created with the utmost care. However, we cannot guarantee the contents' accuracy, completeness or topicality. According to statutory provisions, we are furthermore responsible for our own content on these web pages. In this context, please note that we are accordingly not obliged to monitor merely the transmitted or saved information of third parties, or investigate circumstances pointing to illegal activity. Our obligations to remove or block the use of information under generally applicable laws remain unaffected by this as per §§ 8 to 10 of the Telemedia Act (TMG).`,
    },
    {
      title: "Accountability for links",
      text: `Responsibility for the content of external links (to web pages of third parties) lies solely with the operators of the linked pages. No violations were evident to us at the time of linking. Should any legal infringement become known to us, we will remove the respective link immediately.
             `,
    },
    {
      title: "Copyright",
      text: `Our web pages and their contents are subject to German copyright law. Unless expressly permitted by law (§ 44a et seq. of the copyright law), every form of utilizing, reproducing or processing works subject to copyright protection on our web pages requires the prior consent of the respective owner of the rights. Individual reproductions of a work are allowed only for private use, so must not serve either directly or indirectly for earnings. Unauthorized utilization of copyrighted works is punishable (§ 106 of the copyright law).
             `,
    },
    {
      title: "EU online dispute resolution",
      text: `EU platform for out-of-court online dispute resolution: https://ec.europa.eu/consumers/odr/  We are neither willing nor obliged to participate in a dispute resolution procedure before a consumer arbitration board.
            `,
    },
    {
      title: "Social Media",
      text: `This Imprint also applies to our facebook and Instagram pages
            `,
    },
  ],
  navBarList: ["Events", "Team", "Rates", "FAQ"],
  hero: {
    heroImg: "misc/IMG_0535.JPG",
    header: "",
    teaser: ``,
  },
  cardInfo: [
    {
      title: "New routes per journey",
      description: "We explore new paths each edition.",
      icon: "fa_map",
    },
    {
      title: "Curated playlist",
      description: "A selection of Forró classics as well as new modern hits.",
      icon: "fa_music",
    },
    {
      title: "Paradise scenery",
      description: "Life is best spent outside. Dancing connected with nature",
      icon: "fa_anchor",
    },
  ],

  homeSummary: () => {
    return (
      <>
        <h3>Two passions, one story</h3>
        <p>
          Do you love dancing on <strong>Forró</strong> festivals with
          Forrózeir@s from all over the world? Do you also love{" "}
          <strong>sailing</strong> in well-tempered, crystal-clear waters? We
          do!
        </p>
        <p>
          In 2019, Milena and Bernardo pursued their dream and created this
          unique event to unite both of their passions, Forró and sailing, into
          a floating Forró festival. The <strong>Forró Sailing Week</strong>{" "}
          (FSW) was born.
        </p>
        <p>
          Spend a full week to connect with Forrózeir@s from all around the
          globe, attending dance workshops in special locations like abandoned
          building sited, lonely beaches, vivid plazas, and nautical landing
          stages with our well-selected dancing teachers.
        </p>
        <p>
          Spend the exact same week sailing, exploring cozy coastal towns,
          dreamlike islands, beautiful beaches and on rare occasions even
          watching dolphins racing our yachts.
        </p>
        <p>
          Throughout the days it is sailing, swimming, snorkeling, kite surfing,
          stand-up paddling, doing yoga, relaxing on floaties, sunbathing and
          dancing on deck.
        </p>
        <p>
          In the evenings we have open-pot dinners, group strolls through
          coastal towns and Forró workshops.
        </p>
        <p>
          In the night it is time for our themed Forró parties, caipirinhas,
          stargazing under the clearest midnight sky and on some occasions a
          night swim through bioluminous glowing plankton.
        </p>
      </>
    );
  },
  homeDataAboutUS: {
    head: "FSW25 in Sicily",
    text: `We are back!! After a pause in 2024 the FSW is back for 2025 with full power. Get ready for one more unforgetable event in Sicily`,
    img: "misc/IMG_112011.jpg",
    bulletPoints: [
      {
        icon: <FaMusic />,
        shortText: "Matheus Antunes",
      },
      {
        icon: <FaAnchor />,
        shortText: "Arrival & departure from Marina D'Orlando",
      },
      {
        icon: <FaMap />,
        shortText: "Explore the magical Sicily islands",
      },
    ],
  },

  ourValuesList: {
    head: "Hop on board!",
    text: `Step by step before we set sails.
           `,
    values: [
      {
        head: "Booking Phase",
        stage: false,
        text: `
                `,
      },
      {
        head: "Boat chartering Phase",
        stage: false,
        text: "",
      },
      {
        head: "Crew Allocation Phase",
        stage: false,
        text: "",
      },
    ],
  },

  fotoCard: {
    title: "FSW19 in Croatia",
    description:
      "This is where it all started with our first Forró Sailing Week crew back in 2019. Spending out vacations with those beautiful and amazing people from so many different Forró communities is what gives us fuel to organize this event year after year.",
    url: "./assets/img/img_002.jpeg",
  },

  socialLinks: [
    {
      name: "Facebook",
      stl: "fa_face",
      link: "https://www.facebook.com/profile.php?id=100036867115395",
      color: "rgba(37, 99, 235, 1)",
    },
    {
      name: "Youtube",
      stl: "fa_youtube",
      link: "https://www.youtube.com/watch?v=qRwzN6Py6sY&t=133s",
      color: "rgba(255, 0, 0, 1)",
    },
    {
      name: "Instagram",
      stl: "fa_insta",
      link: "https://www.instagram.com/forrosailingweek/",
      color: "rgba(244, 114, 182, 1)",
    },
  ],

  otherLinks: {
    "About FSW": [
      {
        name: "Imprint",
        link: "/about-fsw",
      },
      // {
      //   name: 'Blog',
      //   link: '',
      // },
      // {
      //   name: 'Github',
      //   link: 'https://github.com/',
      // },
      // {
      //   name: 'Tickets',
      //   link: '',
      // },
    ],
    "Other Resources": [
      {
        name: "Terms & Conditions",
        link: "",
      },
      {
        name: "Privacy Policy",
        link: "",
      },
      {
        name: "Contact Us",
        link: "",
      },
    ],
  },

  faqInfo: {
    title: "FAQs",
    description:
      "Here you can find the most common questions we've been asked so far. Still have questions?Contact us!",
    body: [
      {
        subject: "Booking & Payment",
        topics: [
          {
            question: "I am a solo traveler/dancer. Can I book a spot?",
            answer:
              "Yes, you can and should! Our Sailing week is designed to make it as simple and uncomplicated as possible for you. Just decide for your prefered budget option and go for it. \n In case you have a prefered person to share a cabin with, who will or might join the Sailing week aswell, you can tell this during the booking process, otherwise you will be assigned some other Forrozeir@s of same gender to share the cabin with.",
          },
          {
            question: "What is your cancellation policy?",
            answer:
              "If you decide to cancel your trip, no refund can be given. \n We can only partially reimburse you, if a substitute to your ticket can be found and the later this happens, smaller the odds of this working out. \n PS: Depending on the point in time you make your decision, we might have further restriction of the new participant having to have the same gender as you, since we have a policy to keep people of same gender dividing a cabin. \n So please advise us ASAP if cou can't join anymore and once you confirm to us you are not participating we should place your ticket online immediatly: \n 3.1) If you find someone to take your place, this person has to buy a equivalent ticket to yours with current ticket pricing in our ticket system and we will reimburse you your ticket, but keep a 50€ ADMIN fee. \n 3.2) If we (FSW) manage to sell your ticket for you, we will reimburse you your ticket, but keep a 100€ ADMIN fee. \n 3.3) If we (FSW) or you manage to sell your ticket for you, you can get a Voucher for the same budget in the upcoming FSW event (if one is planned!) and dont pay any fees.",
          },
          {
            question:
              "I want to join for the second time. Do I get a discount?",
            answer:
              "Yes. We give special promotions and offer discount codes to our FSW veterans, and these are shared in our ALTE HASEN Whatsapp group. \n If you are a 'Alter Hase' join the group here: https://chat.whatsapp.com/L5XD0DNFpgWGlb33w9JoSO  or get in touch with directly with us.",
          },
          {
            question: "Is it possible to book a whole boat?",
            answer:
              'Yes, if you manage to gather a group together of at least 8 people, you can reserve a separate boat for your group (if so desired) and get a 50€ discount to every member of the group. For that use the promo code "FULL BOAT" in our ticket system. \n PS: You need to gather INFO from all attendees to answer the mandatory questions at the survey during the booking process for the group.',
          },
        ],
      },
      {
        subject: "Budget",
        topics: [
          {
            question:
              "What are the total costs I have to consider for the FSW?",
            answer:
              "Check the RATES page to know what is or isn't included in the FSW ticket. \n Apart from the Ticket Budget (bronze, silver or gold) of your choice, you shall also account on following extra expenses: \n 1) Travel arrangements: \n 1.1) transportation to and from the port where your boat will depart/arrive... usually flight or ferry + inland transportation. \n 1.2) depending on your travel arrangements, potentially further housing costs prior or after the sailing week \n 2) Boats kitty costs (ca. 150-300€ per person for the week), which covers: \n 2.1) split groceries costs for everything you eat/cook & drink in the boat \n 2.2) boat fuel and marina costs, which vary according to the boat size and of course the fuel consumption (save money and set your sails!! ;) \n 3) Restaurants or other program you decide to do (eg. renting scooters, bycicle, tourist programs...)",
          },
          {
            question:
              "How much should I plan in for the boat's joint expenses (the so called kitty)?",
            answer:
              "Experience shows that something between 150-300€ per person for the whole week should be enough to account for boat fuel, marina fees, onboard food and potentially even a skipper Tipp at the end of the week. \n In the yachting industry it's best practice to pay ca. 10% of the ticket price as tipp, but each person can and should decide for himself. \n\n Those are all variable costs, which depends on the actual consumption (how much sailing vs motoring, anchoring vs sleeping with the boat in a marina, how much food/drinks are bought and finally if you find your skipper deserves a Tipp.",
          },
          {
            question: "How are the boat's joint expenses managed?",
            answer:
              "It's best practice to do a group fund KITTY in the check in day, where each person puts ca. 150-300€ cash and an appointed kitty manager can manage the boat's money. The boat's common expenses are withdrawn from the kitty. \n In case the kitty money dries out before the end of the week, people have to hand in more money. The money that remains at the end of the week should be split back to all payers. The person who manages the kitty shall keep the receipts to cross check at the end of the week. \n An alternative to the kitty is to use some App, but the kitty has proven more efficient in most cases.",
          },
        ],
      },
      {
        subject: "Liability",
        topics: [
          {
            question:
              "Does the boat have insurance? What about the deductible/damage deposit?",
            answer:
              "Yes, charter boats have by default an insurance, so that even in worse case scenario the crew would be only liable for the deductible. \n\n The FSW opted from 2023 onwards to also ADD an insurance for this deductible (so called damage deposit) as default in our events, so that even in case of damage to the boat or boat apparel (eg. dinghies, fenders, winches...), you should not have any further debts. This extra insurance costs in avg. 50€ per person and is already included in your ticket price.",
          },
          {
            question: "Is the Skipper insured?",
            answer:
              "Yes, each FSW skipper must have a third party liability insurance in case of accidents (eg. with other boats or a marina or swimmer...)",
          },
          {
            question:
              "What other insurance type should be considered for this event?",
            answer:
              "We advise FSW participants to close a personal travel insurance, so that you can get your money reimbursed in case you cant join the trip for instance because of illness. Also many other issues like airplane cancellations, baggage and so on may be covered through that. Depending on the insurance coverage, this type of insurance costs range in 50-100€. /n Also might be good checking if you have a valid insurance for overseas medical expenses.",
          },
        ],
      },
      {
        subject: "Accomodation",
        topics: [
          {
            question:
              "How and when will the allocation of participants to the boats happen?",
            answer:
              "1) Once the due date for registering is finished we book the boats. \n 2) When the boats are booked we then allocate the people to the boats in order of reservation date and according to the budget booked. \n 3) We publish the Boats x crew list, when the above is finished...usually around 2 months prior to departure.",
          },
          {
            question: "Where do we sleep during the Forró Sailing Week?",
            answer:
              "In the boat, so there are no hotel expenses! Each boat has around 4/5 cabins. Usually 2 people can sleep per cabin. To be able to offer lower ticket prices overall, we usually make use of the saloon beds and bow cabins as well, which are also sleeping spots in the boats, with some peculiarities (more INFO in our rates session).",
          },
          {
            question: "How big are the cabins and beds?",
            answer:
              "Most of the beds in the boats are double beds. In some rare cases there are bunk beds on top of each other. ",
          },
          {
            question: "Will I have to sleep in a double bed with a stranger?",
            answer:
              "In the registration process you have the option of also naming a person with whom you would like to share a cabin. \n If no preference is given, we will try to place people from the same gender within a cabin. Over 80% of our crew come by themselves and we did not face any compatibility problems, since our crew is always the best. :) \n If you prefer to have a cabin for yourself, you can consider taking a Bow cabin ticket in a catamaran or booking 2 tickets (whole cabin for yourself).",
          },
          {
            question:
              "How many people can sleep on a boat and how many toilets per boat?  ",
            answer:
              "Max. boat capacities take into consideration every available sleeping spot in the boat, including the saloon bed, which we also make use of so that we can offer overall cheaper prices. Max. CAPA depends on the size and layout of the boat, but rule of thumb: \n -40-50ft monohauls: Up to 10 people (or 12 people in case of 5 cabins) \n The boats may have from 2-4 toillets. \n -the 38-50ft catamarans: Up to 12 people \n Most catamarans have 4 toillets available.",
          },
          {
            question: "How many sailing boats will be part of our group?",
            answer:
              "The number of sailing boats vary depending on the dancers/travellers who register. We usually sail out 3-5 sailing boats. We all sail out and moor together.",
          },
        ],
      },
      {
        subject: "Sailing boat",
        topics: [
          {
            question: "What's the deal with food on board?",
            answer:
              "Before the departure, every boat group should write down a grocery list for the week and some crew members go to the market and buy it (expenses to be shared through the kitty). Every boat has a full equipped kitchen (fridge, oven/stove...), but be mindfull there is limited space (especially monohauls). \n PS1: It would be nice to plan at least two days to have dinner on land in a nice harbour city and there are chances of re-supply during the week. \n PS2: me mindfull to vegan or vegetarian people in the boat",
          },
          {
            question: "What about cooking and cleaning?",
            answer:
              "We advise to build kitchen teams  per day for cooking and dish washing or divide tasks to have a cooking manager onboard, but each boat shall set up it's own arrangements.",
          },
          {
            question: "Will I get seasick sailing?",
            answer:
              "It is hard to tell, some people tend to get seasick more easily than others. But after many seasons at sea, sailing with all kinds of people and with a lot of 'newbees' aswell, this was never a problem. If you have fear of getting seasick, there are many pills and tapes in the market that help in this matter.",
          },
          {
            question: "Sailing without any boat/sailing experiences?",
            answer:
              "That is not a problem. We will have experienced skippers/sailors in every boat taking care of that. There is a safety briefing in the beginning of the week to brief you on what you have to watch out for. And every skipper is always happy with some extra pro-active hands onboard. If you want to learn more about sailing share this interest with your skipper and he will be glad to share his knowledge with you. It's a great chance to informally acquire valuable sailing experience and knowledge.",
          },
          {
            question:
              "Is it possible to learn some sailing skills during the trip?",
            answer:
              "Yes, it is an excellent opportunity to get 'on the job' experience. You will have a knowledgeable skipper with you the whole week and definetly many opportunities to learn sailing and sometimes absorb knowledge only from seeing what is happening. You can try out different sailing positions within a big keel boat. From sails trimmer, to navigator and helmsman/woman. \n If you are eager to learn more tell your skipper you would like to be his deck hand. Every skipper needs help from at least 2 people, when coming into or out of a port or anchoring manouvers...and these people usually need extra instructions.",
          },
        ],
      },
      {
        subject: "Skippers",
        topics: [
          {
            question: "Who drives the boat? Who is the captain?",
            answer:
              "Every boat has a skipper and he/she is the captain on board and always has the last word. \n The actual driving of the boat is one of the easiest tasks and is often delegated to a crew member or the Autopilot :). There are plenty of other tasks for the skipper, which are beyond most people's understanding at first sight. But make sure to help your skipper as much as possible and keep him fed and the boat tidy, so that he can ensure you also a safe and pleasant trip. Also by being proactive and taking up tasks, you can learn quite  a bit with these sea wolfs. \n\n The skippers have all the due licenses (similar to drivers license, but for the sea and considerably more extensive) and plenty of sailing/skippering experience. \n In our events every skipper must also have a skipper liability insurance. Luckly we never had to make use of these, but better safe than sorry.",
          },
          {
            question:
              "Do the skippers have to pay for the extra expenses (eg. food, boat fuel, marina)?",
            answer:
              "No, the skippers should not be included on the share of extra expenses/ kitty. This is common practice in the Boating/Skipper industry. It's also the CREW's responsability that the skippers get 3 meals a day to be able to focus on his job of skippering the boat. \n On top of that it is common practice in this industry, that the crew pay 5-10% of it's booking price as Tip to the skipper at the end of the trip, but every person may decide for himself and is completly OPTIONAL. \n Remember that being a skipper is a job... there are big responsabilities involved and they had to acquire lot's of knowledge (and costly licenses) through time to be able to lead a boat by themselves. On top of that every skipper is oblied to have a skipper liability insurance, which is also costly and covered in your package.",
          },
          {
            question: "Who will be my skipper?",
            answer:
              "The skipper for each boat is assigned by the FSW ORGA team based on skippers availabilities & many other criteria. The skipper for each boat is published to the participants in the 'Boats x Crew list' ca. 2 months prior to departure. \n We have some skippers, who have been with us for many years, whose INFO you may find in our TEAMS page. We definetly cant guarantee you will have a skipper of your choice, but you can share your skipper preference during your ticket purchase and we also take this into consideration, when assigning skippers to boats.",
          },
        ],
      },
      {
        subject: "Travel Arrangements ",
        topics: [
          {
            question: "Where do we start and end the trip?",
            answer:
              "The exact harbour depends on the venue and which charter company the boats are booked. This INFO is shared latest 1 month after tickets selling is closed, but at times also prior to that.",
          },
          {
            question: "Do we have a fixed route/ itinerary?",
            answer:
              "No, we know the region we will be exploring and the ORGA team will try to share a rough plan for the week with the places/islands/cities we aim to visit along the week prior to departure. \n But weather (potential storms, wind direction & sea conditions), timing, program and many other variables influences the decision regarding the best spot to spend the night. \n The ultimate decision of where to park the boat is from the skipper --> which port, mooring buoy or anchorage. The crew is welcome to share preferences with the skipper. \n There is a daily skippers meeting where we decide and try to share more precise program for the flotilla at least for the next 24 hours.",
          },
        ],
      },
      {
        subject: "General",
        topics: [
          {
            question: "Any tips for clothing and what to wear during sailing?",
            answer:
              "Early season should be around 25-28°C, so swimsuit, flip flops, sunglasses and sunscreen are must haves. \n Good to have a good windproof jacket and a wool hat. If it gets cold the temperatures could hit worse case scenario ca. 15-20°C at night.",
          },
          {
            question: "What activities can we do during the trip?",
            answer:
              "Besides sailing and dancing, there will be multiple options to go swimming and snorkeling, sunbathing, reading on deck or on the beach, to go for a walk or hiking on land, finding some time to do Yoga or discovering beautiful cities & islands. Sometimes you may also rent scooters & co. to discover the islands & so much more... \n There might be possibility to kitesurf or wing foil aswell. Get in touch in advance, if you want to try this.",
          },
          {
            question: "Are there any health requirements for joining the FSW?",
            answer:
              "Any medical conditions that might constrain you traveling for a week with the Forró Sailing Week must be previously notified to us, so that we can access the potential impacts related to it.",
          },
        ],
      },
      {
        subject: "Forró",
        topics: [
          {
            question: "Will we have Forró workshops?",
            answer:
              "YES!!!  We have managed in avg. 4 workshops per FSW. The exact plan depends on timing, participants dancing level and the workshop locations we discover, so we can't guarantee a certain amount of workshops, but the ORGA team gives it's best to accomodate it and also give plenty of dancing opportunities.",
          },
          {
            question: "Where will we dance Forró?",
            answer:
              'Along the week we have plenty of opportunities to dance on land... be at the deck/port, at the beach or at completly random places we discover throughout the trip. \n It is possible to dance in the boats and we do it a lot, but be aware to not hurt your foot or fall and best to not try it with big waves and while moving. \n Catamaran boats usually offer more dancing space with big saloons... we usually remove the tables and turn it into a  "dance floor". Sometimes we park boats next to each other to have a even more extended dance floor.',
          },
        ],
      },
    ],
  },

  teamData: {
    title: "Here is our crew!",
    description: `According to the National Oceanic and Atmospheric Administration,
                  Ted, Scambos, NSIDClead scientists, Forró is for all.`,
    team: [
      {
        name: "Bernardo Low-Beer",
        position: "Organizer & Captain",
        social: [
          { facebook: "https://www.facebook.com/lowbeer" },
          { insta: 2 },
          { linkedin: "" },
        ],
        foto: "team-1-800x800.jpg",
      },
      {
        name: "Romina Hadid",
        position: "Marketing specialist",
        social: [
          { facebook: "" },
          { insta: "" },
          { linkedin: "" },
          { tiktok: "" },
          { youtube: "" },
        ],
        foto: "team-2-800x800.jpg",
      },
      {
        name: "Alexa Smith",
        position: "Associate",
        social: [{ facebook: "" }, { insta: "" }, { tiktok: "" }],
        foto: "team-3-800x800.jpg",
      },
      {
        name: "Milena-Marie Zöller",
        position: "Founder and Ceo",
        social: [
          { facebook: "https://www.facebook.com/milenamarie.zoller" },
          { linkedin: "" },
        ],
        foto: "team-4-470x470.png",
      },
    ],
  },
  teamDataFull: [
    {
      id: "bernardo-low-beer",
      name: "Bernardo Lichtenberg Low-Beer",
      description: `Bernardo always wanted to unite his passions for sailing and dancing Forró, after meeting Milena this was the last push needed to make
      it happen. Since 2018 they jointly organize the Forró Sailing Week. Bernardo is also sailing since his young ages in the optimist class and later
      even participated in the Rio Pan-American Games. With lots of experience in big boats as well, he is one of the FSW skippers. As a passionate
      Forró dancer, he was also teaching classes in Essen, then was founder of Forró in Münster (Germany) and Forró in Pavia (Italy).
      His main job is a logistics engineer, which enables him to pull this all together.`,
      groupName: "Corestaff",
      role: "Founder, Organizer & Skipper",
      imgPath: "team/B00.jpeg",
      editions: ["fsw19", "fsw20", "fsw21", "fsw22", "fsw23"],
    },
    {
      id: "milena-marie-zoeller",
      name: "Milena-Marie Zöller",
      description: `Milena always loved to dance and to be connected with the ocean. This combination brought her to live in Lisbon, where
      she finally discovered her passion for Forró. After meeting Bernardo in 2017, he introduced her
      to a wonderful new world: sailing. It happend that they were on a family- and friends-sailing-trip in Sardinia in 2018, where the concrete
      idea was born and the plan was set: more people need to fall in love with this beautiful combination! Nowadays Milena is working on her first
      sailing license. She lives in Berlin and in her main profession she works with Communications, Public Affairs and Events - a combination, that also
      the Forró Sailing Week benefits from.`,
      groupName: "Corestaff",
      role: "Founder, Organizer & Marketing",
      imgPath: "team/IMG_3558.jpg",
      editions: ["fsw19", "fsw20", "fsw21", "fsw22", "fsw23"], // possible values: 'fsw19', 'fsw20', 'fsw21', 'fsw22' or 'fsw23'
    },
    {
      id: "gerrit-fachinger",
      name: "Gerrit Fachinger",
      description: `Gerrit discovered his passion for Forró in 2019 in Aachen. Looking for Forró festivals he stumbled upon the FSW and
      signed up for the FSW20 event. Shortly after the event got postponed due to Covid. However, later in summer 2020 he got a call from Bernardo
      offering him a spot on a boat in Croatia that led to a memorable sailing trip which sparked his curiosity in learning more about sailing aswell.
      In the subsequent 2 years he finished the legal requirements regarding sailing and radio licenses to stir a sailing yacht. During that time, he 
      also became friends with Bernardo and Milena, gained practical experience on cruising yachts and participated as co-skipper in the FSW21. 
      In 2022 he finally joined the FSW22 as a qualified skipper himself, hopefully also for future events to come.`,
      groupName: "Corestaff",
      role: "Skipper & Organizer",
      imgPath: "team/IMG_0482-min.jpeg",
      editions: ["fsw20", "fsw21", "fsw22", "fsw23"], // possible values: 'fsw19', 'fsw20', 'fsw21', 'fsw22' or 'fsw23'
    },
    {
      id: "sven-de-causmaecker",
      name: "Sven De Causmaecker",
      description: `Sven has been dancing all his life. He started Forró back in 2016 and since then has never stopped. He is particularilly
      fond of the roots music and dancing style. Speaking about roots, thanks to his Belgian roots, he has always had a strong connection
      to the sea and he has explored many different ways of combining the wind and the sea to have a good time. Sailing boats have slowly
      started replacing surf boards and in this he is continuing a long standing family tradition. His Father and Grandfather have also been
      sailors in their time. So combining sailing, dancing Forró and good friends sounded like a great idea to him from the start and he has
      participated in all previous sailing weeks as a skipper of one of our boats. `,
      groupName: "Skippers & Co-Skippers",
      role: "Skipper",
      imgPath: "team/SvenDC.jpg",
      editions: ["fsw19", "fsw21", "fsw22"], // possible values: 'fsw19', 'fsw20', 'fsw21', 'fsw22' or 'fsw23'
    },
    {
      id: "iljya-kalai",
      name: "Iljya Kalai",
      description: `Iljya's fascination with Brazil dates back to an Intro to Jazz class, where he heard his first Bossa Nova. Soon after, he
      started learning Portuguese and traveled to Brazil where he discovered Forró. Years later, an audacious plan was born: to buy a sailboat
      and sail to Brazil. Now he is grateful to contribute his experience as a skipper to such a wonderful and out of the ordinary community
      of sailor dancers.`,
      groupName: "Skippers & Co-Skippers",
      role: "Skipper",
      imgPath: "team/Iljya00.jpeg",
      editions: ["fsw21", "fsw22", "fsw23"], // possible values: 'fsw19', 'fsw20', 'fsw21', 'fsw22' or 'fsw23'
    },
    {
      id: "josip-drinovac",
      name: "Josip Drinovac",
      description: `Josip is a full time professional skipper and owner of the Lighouse Sailing - Yacht Charter.
      He supports us by providing us great charter deals and also jumps on board now and then as a skipper.
      The FSW also profits from his expert advices in the Yachting industry.`,
      groupName: "Skippers & Co-Skippers",
      role: "Skipper",
      imgPath: "team/Josip.jpeg",
      editions: ["fsw19"], // possible values: 'fsw19', 'fsw20', 'fsw21', 'fsw22' or 'fsw23'
    },
    {
      id: "thomas-florian",
      name: "Thomas Florian",
      description: `Thomas got into dinghy sailing in 2009, into dancing Forró 2015. FSW combines both of these passions. Secret power
      is knowing an inappropriate joke for every situation. Likes to prepare morning pancakes. `,
      groupName: "Skippers & Co-Skippers",
      role: "Skipper",
      imgPath: "team/ThomasF.jpg",
      editions: ["fsw21", "fsw22"], // possible values: 'fsw19', 'fsw20', 'fsw21', 'fsw22' or 'fsw23'
    },
    {
      id: "nastasia-oppermann",
      name: "Nastasia Oppermann",
      description: `Thanks to her granddad, Nastasia is in touch with sailing since her childhood. She made her first certificate
      in 2017 and started to teach sailing in the same school. After some seasons, she spent 2 semesters in Portugal because of her
      love to Forró. At the moment she is finishing her studies and keeps on looking forward to be at sea again.`,
      groupName: "Skippers & Co-Skippers",
      role: "Co-Skipper",
      imgPath: "team/WhatsApp Image1.jpeg",
      editions: ["fsw19", "fsw20", "fsw21", "fsw22"], // possible values: 'fsw19', 'fsw20', 'fsw21', 'fsw22' or 'fsw23'
    },
    {
      id: "fernando-pierobon",
      name: "Fernando Pierobon",
      description: `Fernando started dancing Forró and sailing on the first Forró Sailing Week and has been with us ever since. Very talented mechanical
      craftsmanship and always kind to help others, he is a big added value to the FSW. He has the patience of a saint and if you want to learn
      some sailing basics or also some other random stuff, there is quite some knowledge you can absorb from this guy.`,
      groupName: "Skippers & Co-Skippers",
      role: "Co-Skipper",
      imgPath: "team/FernandoP.jpeg",
      editions: ["fsw19", "fsw21", "fsw22", "fsw23"], // possible values: 'fsw19', 'fsw20', 'fsw21', 'fsw22' or 'fsw23'
    },
    {
      id: "sami-barasi",
      name: "Sami Barasi",
      description: `Sami participated in the FSW21 & FSW22 and got in love with it. He got his sailing licenses and will start in the FSW23 as a Co-Skipper. Way to go!`,
      groupName: "Skippers & Co-Skippers",
      role: "Co-Skipper",
      imgPath: "team/Sami.jpeg",
      editions: ["fsw21", "fsw22", "fsw23"], // possible values: 'fsw19', 'fsw20', 'fsw21', 'fsw22' or 'fsw23'
    },
    {
      id: "matheus-antunes",
      name: "Matheus Antunes",
      description: `Matheus Antunes is a Forró dance teacher living in Germany.
      Born in Belo Horizonte/MG, Brazil, he started having dance classes at Pé Descalço in 2012. Two years later,
      for the first time living abroad (Germany), he met several Forró Festivals in Europe, attending to workshops
      of teachers of many diverse styles, besides being a regular student for some time at Munique Dança Forró and
      for a year at Forró de Colônia, where he had his first guided teaching experiences in 2015.
      In 2016, back in BH, Matheus started teaching at the university UFMG. And besides spreading his passion and
      knowledge about Forró, he started expanding it again in 2017 at the school Simbora Dançar, this time specially
      focused on a dance style commonly referred to as "roots".
      In 2018 he decided to move back to Germany, where he became teacher and vice-president at Forró de Colônia,
      even creating a new filial of the school in 2019 in Duisburg. Finally, in the end of 2021, Matheus moved to
      Berlin and founded his own Forró group, where he still teaches today.`,
      groupName: "Forró Teachers & Musicians",
      role: "Forró Teacher",
      imgPath: "team/IMG_12346.jpg",
      editions: ["fsw23"], // possible values: 'fsw19', 'fsw20', 'fsw21', 'fsw22' or 'fsw23'
    },
    {
      id: "fabio-reis",
      name: "Fábio Reis",
      description: `Fábio Reis is a Forró researcher and teacher. He insists that the music should be the center of our dance. He developed his didactic way of teaching, focusing on feeling, interaction, the freedom to express yourself and the dance individually. For Fábio dancing Forró goes far beyond than running steps and spins.`,
      groupName: "Forró Teachers & Musicians",
      role: "Forró Teacher",
      imgPath: "team/fabioreis.jpg",
      editions: ["fsw23"], // possible values: 'fsw19', 'fsw20', 'fsw21', 'fsw22' or 'fsw23'
    },
    {
      id: "diana-richter",
      name: "Diana Richter",
      description: `Diana Richter, born and raised in Düsseldorf, dances passionately since she's three years old. She works as a professional
      dancer, teacher and choreographer in different solo and couple dances. She has been teaching styles like contemporary dance, modern
      dance, elementary dance and much more for 13 years now. \n
      Before, she also practiced ballet, cheerleading and Discodance and participated in many competitions. She became a dancer and choreographer of a dance theater ensemble, which produced successful performances throughout the last years, in 2015.
      Diana studied sports and dance in Cologne and also finished a three year long dancer's education. \n
      Diana started dancing Forró in 2015. Since the beginning of 2017, she is teaching regular Forró and Ladystyling classes in Düsseldorf,
      as well as at national and international Festivals all over the world. She also has knowledge about Zouk, Samba de Gafieira and more couple
      dances. Because her love and passion for dance is so inspiring and important to her, it is an honor for her to share this passion and her
      knowledge with other people.`,
      groupName: "Forró Teachers & Musicians",
      role: "Forró Teacher",
      imgPath: "team/IMG_1234.jpeg",
      editions: ["fsw22"], // possible values: 'fsw19', 'fsw20', 'fsw21', 'fsw22' or 'fsw23'
    },
    {
      id: "anax-caracol",
      name: "Anax Caracol",
      description: `Anax Caracol is a nortista brasileiro, born in the heart of Brazil in the state of Tocantins. Between
      the amazon and the Northeast of the country. His passion for dance was sparked from a very early age, inspired by his
      family who taught him the value and importance of the rich cultural heritage of the North and North-East of Brazil. \n
      Anax's interests grew once he moved to the cultural Coco town of Arcoverde in the back lands of the state of Pernambuco at
      the age 8 years old. From there, he explored and learned many types of popular dances such as Coco, Maracatu, Jongo, Samba
      de Roda, Brazilian contemporary, Orixas dances and of course Forró. \n
      Anax has trained with folkloric dance masters such as Antonio Nobrega, Lula Calixto, Omolu and others who inspired him in his
      life and career as a dancer. His experiences led to his participation in one of the top Afro companies in the country, the Bale
      Folclorico da Bahia and the well-known classic company of Carmen Serra in Spain. \n
      Since moving to London in 2009, Anax became a key part of the Forró scene in the UK, teaching regular classes in London, Birmingham,
      Bristol and in different universities around UK. He also teaches and performs at numerous dance festivals around Europe and the all
      globe in such places as Canada, Japan and Russia. Anax is a teacher and founder of Forró Brincado. A project that brings Brazilian
      popular culture from the northeast to the UK and all of Europe by dance classes and weekly and yearly events. Anax Forró style is an
      amalgamation of many forms of movement and expression, and is heavily influenced by Afro and Indigenous background, where use of hibs
      , body language and footwork is emphasized, making the dance more playful and fun.`,
      groupName: "Forró Teachers & Musicians",
      role: "Forró Teacher",
      imgPath: "team/Anax.jpg",
      editions: ["fsw21"], // possible values: 'fsw19', 'fsw20', 'fsw21', 'fsw22' or 'fsw23'
    },
    {
      id: "tiago-moraes",
      name: "Tiago Moraes",
      description: `Tiago is a Brazilian Ballroom Dance teacher for Zouk, Forró & Samba de gafiera. Based in Cologne
      Germany. His dance style has a well-balanced focus on technique, musicality and connection. Due his
      graduation as a strength and conditioning coach his method of teaching is really well structured and full
      of touchable details, making the classes really clear! Tiago has a contagious energy when dancing or
      teaching that makes you feel energized & good humoured! So, expect a class (or a dance) fully of details
      but not boring! Balance is everything!`,
      groupName: "Forró Teachers & Musicians",
      role: "Forró Teacher",
      imgPath: "team/index12.jpg",
      editions: ["fsw19"], // possible values: 'fsw19', 'fsw20', 'fsw21', 'fsw22' or 'fsw23'
    },
    {
      id: "terra-jiordano-pasqualini",
      name: "Terra Jiordano Pasqualini",
      description: `Terra Pasqualini was born in Minas Gerais and grew up in São Paulo. At the age of 15 years, he
      discovered the mid-90s strongly growing Forró scene in São Paulo and began to learn this dance and to frequent
      the booming "Casas de Forró" of São Paulo.
      The upswing of this new cultural movement caused an urbanization of the traditional "Forró Pé de-Serra". This new
      interpretation and development of Forró is what stamps Terra's dance stil and also what he transmits to his pupils.
      Creator of the first and grea Forró Festival outside Brazil, the Forró de Domingo Festival, Terra nowadays
      developed spaces for dialogue, with the purpose of uniting the forces of opinion makers within the Forró scene to
      create a movement that knows how to support everyone. Since 2002, he lives in Germany and is considered one of the
      main characters in the dissemination of Forró in Europe.`,
      groupName: "Forró Teachers & Musicians",
      role: "Forró Teacher",
      imgPath: "team/TerraPasqualini3.jpg",
      editions: ["fsw19"], // possible values: 'fsw19', 'fsw20', 'fsw21', 'fsw22' or 'fsw23'
    },
    {
      id: "gabriel-walsh",
      name: "Gabriel Walsh",
      description: `Gabriel Walsh is an English musician who in 2014 traveled to Brazil and fell madly in love with Forró,
      so much so that he was inspired to buy a accordion and start resuming. He took class with the great accordioners of
      Brazil and now lives in Lisbon, playing Forró, jazz and chorinho.`,
      groupName: "Forró Teachers & Musicians",
      role: "Sanfoneiro",
      imgPath: "team/GabrielWalsh.jpg",
      editions: ["fsw22"], // possible values: 'fsw19', 'fsw20', 'fsw21', 'fsw22' or 'fsw23'
    },
    {
      id: "helena-coelho",
      name: "Helena Coelho",
      description: `At the age of 8, Helena performed at a live TV show for the first time and fell in love with the magical
      world of media. Born in Porto Alegre in a family of artists, she grew up on and backstage singing and dancing in several
      artistic productions - from musicals to song albuns and video clips. Professionally, she combined her passions for arts
      and the media pursuing a career in cultural journalism having reported and hosted shows for international audiences.
      Ironically, it was in Germany that Forró came to her life… and because one good thing leads to another she lived the dream of
      meeting and joining the FSW team: can you think of more inspiring images than people dancing to the wave beats of turquoise waters?`,
      groupName: "Partners",
      role: "Content Producer and ORGA support",
      imgPath: "team/IMG_3289.JPG",
      editions: ["fsw19", "fsw21"], // possible values: 'fsw19', 'fsw20', 'fsw21', 'fsw22' or 'fsw23'
    },
    {
      id: "ciaran",
      name: "Ciaran",
      description: `Ciarán has been working in web agencies as a technical account manager for 10 years, and organising Forró festivals
      and weekly parties in Dublin for 3 years.
      He is passionate about community and the impact that our social technology has on them. As part of Plug.events, his project to support
      the Forró community through accurate event information and communication across communities outside of social media, Ciaran provides a
      simple ticketing service to select Forró organisers for free. `,
      groupName: "Partners",
      role: "Provider of ticket system Tuvens",
      imgPath: "team/Ciaran.png",
      editions: [], // possible values: 'fsw19', 'fsw20', 'fsw21', 'fsw22' or 'fsw23'
    },
  ],

  eventsData: [
    {
      id: "fsw19",
      title: "Marina Baotic / ACI Marina Split",
      city: "Split",
      region: "Croatia",
      description: `We did it! The first Forró Sailing Week!
      Two catamarans and one monohull, packed with 33 Forrózeir@s from 10+ countries, 2 amazing teachers (Tiago & Terra)
      and our brave skippers, all together set sails for the islands of northern Dalmatia in Croatia.
      During the week we had four memorable forró workshops. We danced in ancient ruins by the beach,
      in lively squares, provided with free shots by the hospitable local residents, who were amazed by our dances,
      and finally in an abandoned building site of a hotel, with a magnificent view over our anchorage.
      The grand finale was our fish themed Forró party. Even a storm and heavy rain could not stop us!`,
      teachers: ["Tiago Moraes", "Terra (Jiordano Pasqualini)"],
      musicians: [],
      skippers: ["Bernardo Low-Beer", "Josip Drinovac", "Sven De Causmaecker"],
      boats: ["Lagoon 450F", "Lagoon 40", "Bavaria 50"],
      imgPath:
        "https://www.baotic-yachting.com/datastore/imagestore/original/1607695568DJI_0102.jpg",
      date: ["May 25, 2019", "June 01, 2019"],
      participants: 33,
      cost: "",
      currency: "EUR",
    },
    {
      id: "fsw20",
      title: "Marina \n\nBaotic",
      city: "Split",
      region: "Croatia",
      description: `We sold over 40 tickets, booked four boats, contracted teachers and musicians, put a lot of work into the planning
      and then Corona hit. Sadness, exhaustion, abandonment, emptiness spread among us and our participants.
      The plan to bring together as many Forrózeir@s as possible from all regions of the world on the limited space
      of a sailing yacht suddenly seemed absurd and irresponsible. To add to the absurdity, one of our chartered boats was named
      "Corona Borealis". Our plan for the FSW20 was therefore on hold for an unknown period of time. 
      Months later however, in late summer, when the number of cases decreased drastically, we saw a little light in the dark time.
      We got an extremely spontaneous offer from our charter company to book a single boat at an incredibly low price.
      We did not hesitate for long and took this chance to organize a small one-boat FSW. This led to one of the most memorable and most spontaneous trips
      we ever did. Viva la watermelon, viva el white towel crew! Mostly calm winds led to a lot of motoring, but we could reach the far north of Dalmatia
      and had a raft in a small bay of "Otok Ist" with friends of ours who were sailing the region themselves.
      There we spent the night under crystal clear skies, just the right conditions for the passage of the Geminids,
      an annually recurring meteor stream that produces hundreds of shooting stars. Our entire Route: 
      Marina Boatic - Otok Drvenik Mali - Otok Ravni Zakan - Otok Katina - Otok Ist - Sali - Murter - Primosten - Marina Boatic`,
      teachers: [],
      musicians: [],
      skippers: ["Bernardo Low-Beer"],
      boats: ["Monohaul"],
      imgPath:
        "https://www.baotic-yachting.com/datastore/imagestore/original/1607695503DJI_0065.jpg",
      date: ["August 08, 2020", "August 15, 2020"],
      participants: 12,
      cost: "",
      currency: "EUR",
    },
    {
      id: "fsw21",
      title: "Cala dei Sardi / Marina Portisco",
      city: "Olbia",
      region: "Sardinia / Corsica",
      description: `Postponement after postponement, and so much insecurity towards travellig regulations and corona itself, but we finally made it!
      Everyone ed and we hop on board from Olbia towards Bonifacio. Some storms along the way and big seas, but nothing could stop us anymore.
      Amazing Forró workshops in a dust pier of a virgin bay, in an abandoned ruin on the top of Bonifacio and in the Plage de Vo'lpe in Corsica
      with a legendary fire on the beach with some extra adventure after that. The white party in our flag boat went wild midst the abandoned bay
      and we could finish the week with great dances on the boats pier or in an Olbia city square. Big thanks in this event goes also to our 
      amazing skippers, who did a great job under challenging conditions. Our entire Route: 
      Cala Dei Sardi - Cala Di Volpe - Cala Stagnali - Cala Gavetta - Cala Corsera - Bonifatio - Ile Piana - Plage De Balistra -
      Sant' Manza - Pink Beach / Isola Budelli - Marina Di Cala Maggiavolpe - Spiaggi Di Cap Ricciolo - Portisco`,
      teachers: ["Anax"],
      musicians: [],
      skippers: [
        "Bernardo Low-Beer",
        "Sven De Causmaecker",
        "Iljya Kalai",
        "Thomas Florian",
      ],
      boats: ["Lagoon 45", "Lagoon 40", "Lagoon 44", "Bavaria 50"],
      imgPath:
        "https://www.marinebusinessworld.com/photos/marineindustry/yysw363396.jpg",
      date: ["October 02, 2021", "October 09, 2021"],
      participants: 46,
      cost: "",
      currency: "EUR",
    },
    {
      id: "fsw22",
      title: "Poltu Quatu / Marina Cannigione",
      city: "Olbia",
      region: "Sardinia / Corsica",
      description: `FSW22 started with a great dance and meet-up on the pier of the amazing Polto Quatu. 
      First upwind towards Madalena national park and then the crossing to place we had our first incredible workshop
      with Diana at dawn in the corsica beach Plage de Gandina. We then headed north to the far end of this route in Porto Vecchio,
      where we took the dance floor of every square of the city center and ended up with pier dancing and the delicious open pot
      dinner with the best dishes of all our boats. Closing of the week was in a dancing raft party in a bay all for ourselves 
      in the Isola Spargi and finally a 3 course dinner in a hotel in the land side of sardinia with a live band concert
      from Gabriel Gouveia and Margherita Violeiro. Our entire Route: 
      Marina Dell' Orso / Poltu Quatu - Cala Coticcio - Giardinelli - Plage De Balistra - Sant' Manza - Golfe De Rondinara -  Porto Vecchio - 
      Cala G. Marino / Isola Budelli - Cala Cor Sara / Isola Spargi - Cala d'Alga - Marina Dell' Orso / Poltu Quatu`,
      teachers: ["Diana Richter"],
      musicians: ["Gabriel Walsh"],
      skippers: [
        "Bernardo Low-Beer",
        "Sven De Causmaecker",
        "Iljya Kalai",
        "Gerrit Fachinger",
      ],
      boats: ["Lagoon 44", "Lagoon 42", "Lagoon 39", "Oceanis 48"],
      imgPath:
        "https://d1y5anlg0g4t8d.cloudfront.net/709/gallery/medium/marina03-1024x768.jpg",
      date: ["May 28, 2022", "June 04, 2022"],
      participants: 48,
      cost: "",
      currency: "EUR",
    },
    {
      id: "fsw23",
      title: "Alimos Marina",
      city: "Athens",
      region: "Saronic Islands Greece",
      description: `Embarking from Athens, the Forró Sailing Week in June blended magical seafaring with 
        rich cultural activities in the Saronic Islands, Aegean Sea. Aboard five majestic sailboats, 60 
        adventurers experienced enchanting Forró workshops led by Matheus Antunes and Fabio Reis, alongside music, 
        snorkeling, SUP, and communal living. This unique journey transformed participants into a family, united by 
        the sea rhythm and the islands allure, creating unforgettable memories in the heart of Greece.`,
      teachers: ["Matheus Antunes", "Fabio Reis"],
      musicians: [],
      skippers: [
        "Bernardo Low-Beer",
        "Gerrit Fachinger",
        "Naman Jain",
        "Iljya Kalai",
        "Tomasz Wilk",
      ],
      boats: [
        "Lagoon 42",
        "Lagoon 42",
        "Oceanis 51",
        "Oceanis 51",
        "Oceanis 46",
      ],
      imgPath:
        "https://www.athenasail.com/wp-content/uploads/2019/03/Sosta-bagno-nell-isola-di-Spargi-1030x687.jpg",
      date: ["May 27, 2023", "June 03, 2023"],
      participants: 60,
      cost: "",
      currency: "EUR",
      external_links: {
        ticket_system:
          "https://tickets.tuvens.com/e/15/forro-sailing-week-2023/",
      },
    },
    {
      id: "fsw25",
      title: "Marina d'Orlando",
      city: "Sicily",
      region: "Sicily Italy",
      description: `Viva la bella vita`,
      teachers: ["Matheus Antunes"],
      musicians: [],
      skippers: [
        "Bernardo Low-Beer",
        //   'Gerrit Fachinger',
        //    'Naman Jain',
        //    'Iljya Kalai',
        //    'Tomasz Wilk',
      ],
      //      boats: ['Lagoon 42', 'Lagoon 42', 'Oceanis 51', 'Oceanis 51', 'Oceanis 46'],
      imgPath:
        "https://firebasestorage.googleapis.com/v0/b/fsw-http.appspot.com/o/events%2FeventsHeroes%2FFSW25_Hero.jpg?alt=media&token=7799bf06-3c0e-4bfe-a7ec-60d348539820",
      date: ["July 05, 2025", "July 12, 2025"],
      participants: "",
      cost: "",
      currency: "EUR",
      external_links: {
        ticket_system: "https://www.tickettailor.com/events/sailingweek?",
      },
    },
    {
      id: "fsw26",
      title: "Blue Lagoon Marina",
      city: "St. Vincencent in the Grenadines",
      region: "Grenadines Caribbean",
      description: `Lets draw this new adventure together!`,
      teachers: ["to be defined"],
      musicians: [],
      skippers: [
        "Bernardo Low-Beer",
        //    'Gerrit Fachinger',
        //    'Naman Jain',
        //    'Iljya Kalai',
        //    'Tomasz Wilk',
      ],
      //      boats: ['Lagoon 42', 'Lagoon 42', 'Oceanis 51', 'Oceanis 51', 'Oceanis 46'],
      imgPath:
        "https://firebasestorage.googleapis.com/v0/b/fsw-http.appspot.com/o/events%2FeventsHeroes%2FFSW26_Hero.jpg?alt=media&token=bc817c27-f188-4563-810b-e6204fc5a4cc",
      date: ["Mar 28, 2027", "Apr 04, 2027"],
      participants: "",
      cost: "",
      currency: "EUR",
      external_links: {
        ticket_system: "https://www.tickettailor.com/events/sailingweek?",
      },
    },
  ],

  ratesData: [
    {
      id: "general",
      title: "General",
      description: "What you get in the FSW",
      included: [
        "1 week accomodation on a sailing boat of your budget choice (check budget choices below)",
        "Extra boat fees/apparel: SUP, towels, bed lines & final cleaning of the boat, dinghy with motor, extra stove gas bottle",
        "3-4 Forró workshops with a Forró teacher & many other dancing parties/opportunities",
        "Skipper w. liability insurance (potentially a Forrózeir@ aswell)",
        "Insurance for the boat insurance deductible (so called damage deposit)",
        "Company of a whole flotilla of Forrózeir@s",
      ],
      notIncluded: [
        "Boat Kitty (ca. 150-300€) - this is a sort of boat fund to pay common expenses, like: groceries for the boat, marina fees, boats fuel",
        "Travel arrangements: flights, taxi or public transport, further accomodation before or after the trip",
      ],
      paragraphs: [
        " How to decide, which Budget/ticket to take?",
        " A boat is like a full equipped floating apartment. The main difference between the budget/ticket price relies on the size & comfort connected to the boat type you shall get (analog to booking an AirBnB apartment or a car). Higher budget translates into: bigger cabins, beds and common areas (also area to dance Forró ;), more & bigger toilets in the boat.",
        " For gold and sometimes also for silver budgets you may expect a Catamaran instead of a monohull type boat. This has the space advantages listed above, but on the other hand it is not such a sailing adventure as in a monohull. Catamarans are more stable whilst sailing due to the 2 hulls. Which boat type is better is a matter of taste.",
        ' We usually close the SALE of tickets ca. 3 months prior to departure or when our max. boats CAPA is reached. Independent on the Budget you choose, we advise to make your booking ASAP to get early bird ticket prices and to avoid the risk of tickets or a certain ticket category being sold out.  Find our current prices and available tickets in our booking system under "Book Now".',
        " Get more impressions of the budgets and boats to expect, clicking the budget/ ticket category of your interest:",
      ],
      PhotoGallery: ["", "", "", "", "", ""],
    },
    {
      id: "gold",
      title: "Gold",
      description: "40 to 45ft Catamaran",
      paragraphs: [
        "The gold budget boat, often referred to as \"flag boat\" because it is the biggest boat in our flotilla,  is for sure a catamaran. Depending on the market deals we get it should be something between a 40-45 foot CAT.",
        "Catamarans are champions in size since they are not only long, but also very wide, so you can count with the a large internal and external saloon area with table (the tables are usually removable, so that we can turn it into a dance floor) and also plenty of space in the foredeck for sunbathing.",
        "Every gold budget boat cabin is equipped with an own bathroom and also plenty of storage space (for clothes & co).",
        "CATs also have the particularity of having bow cabins, which is an option for single travelers, who one to sleep alone. Check more about this option under the Saloon & bow cabin rate",
        "Often gold budget boats come with some other 'gadgets'... Mostly with a generator and sometime with water desalination machines, so that there is less risk on running out of electricity or dry on water. Also the dinghy (tender boat) for catamarans are usually amongst the most powerful.",
      ],
      typicalBoats: [
        {
          boat: "Lagoon 42",
          googleLink:
            "https://www.google.com/search?client=firefox-b-d&q=Lagoon+42",
        },
        {
          boat: "Lagoon 45",
          googleLink: "https://www.google.com/search?q=lagoon+45",
        },
      ],
      PhotoGallery: ["", "", "", "", "", ""],
    },
    {
      id: "silver",
      title: "Silver",
      description: "45 to 55ft Monohull or ca. 40ft Catamaran",
      paragraphs: [
        "Silver budget enables us a budget that depending on the available deals could turnout to be a bigger monohull (45-55ft) or a smaller catamaran (ca.40ft). Until now we always managed to get good catamaran deals, but this might not always be the case.",
        "Either way, It enables you to get in comparison to bronze budget, bigger boats from brands which offer more interior comfort... such as dufour, which is like the mercedes of the seas. ",
        "Mostly silver boat cabins have an own bathroom (so 4 toilets per boat).",
      ],
      typicalBoats: [
        {
          boat: "Lagoon 40",
          googleLink: "https://www.google.com/search?q=Lagoon+40",
        },
        {
          boat: "Bavaria 50",
          googleLink: "https://www.google.com/search?q=bavaria+50",
        },
        {
          boat: "Dufour 460 GL",
          googleLink: "https://www.google.com/search?q=Dufour+460+GL",
        },
      ],
      PhotoGallery: ["", "", "", "", "", ""],
    },
    {
      id: "bronze",
      title: "Bronze",
      description: "40 to 50ft Monohull",
      paragraphs: [
        "Bronze budget boats are monohulls, which can range in size from 40 to 50 foot. Monohulls have the disadvantage of being less spacious in comparison to other budget boats, but it is the coolest sailing experience.",
        "The cabins  & toilets for the monohulls are usually smaller and more narrow, but still very comfy. ",
        "We usually manage to get boats with 4 toilets, but depending on the exact boat we get, we could have the case of having only 3 toilets, which need to be divided through the whole crew.",
      ],
      typicalBoats: [
        {
          boat: "Sun Odyssey 49i",
          googleLink: "https://www.google.com/search?q=Sun+Odyssey+49",
        },
        {
          boat: "Bavaria 46",
          googleLink: "https://www.google.com/search?q=bavaria+46",
        },
      ],
      PhotoGallery: ["", "", "", "", "", ""],
    },
    {
      id: "sbc",
      title: "Saloon & Bow Cabin",
      description:
        "Saloon & Bow Cabin Tickets are offered at a discounted price in comparison to the cabin tickets for every Budget Rate (Bronze, Silver, Gold).",
      paragraphs: [
        "Saloon Cabins",
        "PROs:",
        "- bed is wider than others and usually more air flow during hot days",
        "- there might me also other sailors cuddling in your bed (check the picture)",
        "CONs:",
        "- need to unmount/mount dinning table every day. The living room offers very limited privacy and you are usually the last to be able to sleep and first one to wake up and there is limited place to store your clothes.",
        "- you don't have an own bathroom, so you have to share the bathroom with other crewmembers, that booked a cabin",
        "Bow Cabins (only available in Gold and sometimes Silver budget boats)",
        "PROs:",
        "- sleep by yourself, more privacy",
        "CONs:",
        "- you don't have an own bathroom, so you have to share the bathroom with other crewmembers that booked a cabin",
        "- limited space and the door to your 'room' is actually a window",
        "Make sure to write down during ticket purchase, in case you have a preference over one of those 2 cabin options, when you book it. Here are some examples of those cabins:",
      ],
      typicalBoats: [],
      PhotoGallery: ["", "", "", "", "", ""],
    },
  ],
};

export default DATA;

/*
General:
  - About Us, Motivation, FAQ, Contact
    -- We can make rates linked to the latest FSW event

- Edition:
  FSW22, ...
  FSW23, routes, rates, photos, general, special guests...

- Rates:
  gold, silver, bronze
*/
